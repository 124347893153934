export const CONDITIONS_REQUIRED = 3;
export const MAX_LENGTH = 64;
export const MIN_LENGTH = 8;

const regexConditions = {
  hasLowerCaseLetter: /[a-z]/,
  hasNumber: /\d/,
  hasSpecialCharacter: /[^A-Za-z0-9\s]/,
  hasUpperCaseLetter: /[A-Z]/,
};

export const applyRegex = (regexType, password) => {
  return regexConditions[regexType] ? regexConditions[regexType].test(password) : false;
};

export const isPasswordValid = (password) => {
  let conditionsMet = 0;

  Object.keys(regexConditions)
    .forEach(condition => applyRegex(condition, password) && conditionsMet++);

  return validatePasswordLength(password) && conditionsMet >= CONDITIONS_REQUIRED;
};

export const validatePasswordLength = (password) => {
  return password.length >= MIN_LENGTH && password.length <= MAX_LENGTH;
};
