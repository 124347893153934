<template>
  <VueHcaptcha
    challengeContainer="captcha"
    ref="hCaptchaRef"
    v-click-outside="closeCaptcha"
    @challengeExpired="handleChallengeExpired"
    @closed="handleClosed"
    @error="handleError"
    @expired="handleExpired"
    @opened="handleOpened"
    @verify="handleVerify"
    :sitekey="captchaSiteKey"
    :size="size"
  />
</template>

<script>
  import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

  export default {
    name: 'HCaptcha',

    components: {
      VueHcaptcha,
    },

    props: {
      size: {
        type: String,
        default: 'invisible',
        validator: function (value) {
          return ['normal', 'compact', 'invisible'].indexOf(value) !== -1;
        },
      },
    },

    methods: {
      closeCaptcha() {
        this.$refs.hCaptchaRef.hcaptcha.close(this.$refs.hCaptchaRef.widgetId);
      },

      execute() {
        this.$refs.hCaptchaRef.execute();
      },

      handleChallengeExpired() {
        this.$emit('onChallengeExpired');
      },

      handleClosed() {
        this.$emit('onClosed');
      },

      handleError(err) {
        this.$emit('onError', err);
      },

      handleExpired() {
        this.$emit('onExpired');
      },

      handleOpened() {
        this.$emit('onOpened');
      },

      handleVerify(captcha, eKey) {
        this.$emit('onVerify', captcha, eKey);
      },

      reset() {
        this.$refs.hCaptchaRef.reset();
      }
    }
  };
</script>
