<template>
  <LayoutMainPages>
    <div class="flex">
      <!-- Left side -->
      <div :class="`${!isMobileDevice ? 'left-side' : ''} mr-14 w-full xl:mr-0`">
        <UpdatePassword />
      </div>
      <SecurityBackground class="xl:hidden" />
    </div>
  </LayoutMainPages>
</template>

<script>
import { LayoutMainPages } from '@/components/misc';
import UpdatePassword from '@/components/security/UpdatePassword';
import SecurityBackground from '@/components/security/SecurityBackground';

export default {
  name: 'ChangePassword',
  components: {
    LayoutMainPages,
    UpdatePassword,
    SecurityBackground,
  },
  async mounted() {
    await this.$store.dispatch('events/track', {
      event: 'CHANGE_PASSWORD_NEW_PASSWORD_VIEWED',
    });
  }
};
</script>

<style scoped>
.left-side {
  max-width: 606px;
}
</style>
