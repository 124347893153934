<template>
  <div class="w-full">
    <div class="text-n-2xl font-semibold">{{$t('password.must')}}:</div>
    <div class="flex justify-between mt-n-xl">
      <div class="w-full text-n-lg font-medium">
        {{$t('password.between_characters', { minLength, maxLength })}}
      </div>
      <div>
        <Checkbox v-model="passwordLength" disabled />
      </div>
    </div>
    <div class="text-n-lg font-semibold mt-n-2xl">{{$t('password.include_at_least', { conditionsRequired })}}:</div>
    <div class="flex justify-between mt-n-2xl">
      <div class="w-full text-n-lg font-medium">
        {{$t('password.one_number')}}
      </div>
      <div>
        <Checkbox v-model="hasNumber" disabled />
      </div>
    </div>
    <div class="flex justify-between mt-n-xl">
      <div class="w-full text-n-lg font-medium">
        {{$t('password.capital_letter')}}
      </div>
      <div>
        <Checkbox v-model="hasUpperCaseLetter" disabled />
      </div>
    </div>
    <div class="flex justify-between mt-n-xl">
      <div class="w-full text-n-lg font-medium">
        {{$t('password.lower_case_letter')}}
      </div>
      <div>
        <Checkbox v-model="hasLowerCaseLetter" disabled />
      </div>
    </div>
    <div class="flex justify-between mt-n-xl">
      <div class="w-full text-n-lg font-medium">
        {{$t('password.special_character')}}
      </div>
      <div>
        <Checkbox v-model="hasSpecialCharacter" disabled />
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/misc/Checkbox';

import {
  applyRegex,
  isPasswordValid as isFieldValid,
  validatePasswordLength,
  CONDITIONS_REQUIRED,
  MAX_LENGTH,
  MIN_LENGTH,
} from '@/utils/validate-password';

export default {
  name: 'ValidatePassword',

  components: {
    Checkbox,
  },

  data() {
    return {
      currentField: this.password,
      conditionsRequired: CONDITIONS_REQUIRED,
      maxLength: MAX_LENGTH,
      minLength: MIN_LENGTH,
    };
  },

  props: {
    password: {
      type: String,
      required: true,
    },
    confirmPassword: {
      type: String,
      default: '',
      required: false,
    },
  },

  methods: {
    emitSetIsPasswordValid() {
      this.$emit('setIsPasswordValid', {
        isPasswordValid: this.isPasswordValid,
        isConfirmPasswordValid: this.isConfirmPasswordValid,
      });
    },

    selectField(field) {
      switch (field) {
        case 'password':
          this.currentField = this.password;
          break;
        case 'confirmPassword':
          this.currentField = this.confirmPassword;
          break;
      }
    },
  },

  computed: {
    isConfirmPasswordValid() {
      return isFieldValid(this.confirmPassword);
    },

    isPasswordValid() {
      return isFieldValid(this.password);
    },

    hasLowerCaseLetter() {
      return applyRegex('hasLowerCaseLetter', this.currentField);
    },

    hasNumber() {
      return applyRegex('hasNumber', this.currentField);
    },

    hasSpecialCharacter() {
      return applyRegex('hasSpecialCharacter', this.currentField);
    },

    hasUpperCaseLetter() {
      return applyRegex('hasUpperCaseLetter', this.currentField);
    },

    passwordLength() {
      return validatePasswordLength(this.currentField);
    },
  },

  watch: {
    confirmPassword() {
      this.currentField = this.confirmPassword;
    },

    isConfirmPasswordValid() {
      this.emitSetIsPasswordValid();
    },

    isPasswordValid() {
      this.emitSetIsPasswordValid();
    },

    password() {
      this.currentField = this.password;
    },
  },
};
</script>

<style scoped>

</style>
