<template>
  <form @submit.prevent="updatePassword">
    <GoBack @click="goBack" class="h-n-3xl fill-current text-text-body cursor-pointer" />

    <div class="mt-n-4xl text-n-5xl font-bold">{{$t('password.change_password')}}</div>

    <div class="mt-n-4xl font-semibold">{{$t('password.enter_current_password')}}</div>

    <div class="relative">
      <input
        ref="currentPasswordRef"
        v-model="currentPassword"
        class="v2 mt-n-2xl pl-n-4xl pr-n-8xl py-n-3xl"
        :type="showCurrentPassword ? 'text' : 'password'"
        data-testid="input-password"
        data-lpignore="true"
      />
      <div @click="showCurrentPassword=!showCurrentPassword" class="absolute top-0 right-0 mt-n-5xl mr-n-3xl text-n-md font-medium cursor-pointer">
        {{$t(showCurrentPassword ? 'password.hide' : 'password.show')}}
      </div>
    </div>

    <div class="mt-n-4xl font-semibold">{{$t('password.enter_new_password')}}</div>

    <div class="relative">
      <input
        @focus="$refs.validatePassword.selectField('password')"
        ref="newPasswordRef"
        v-model="newPassword"
        class="v2 mt-n-2xl pl-n-4xl pr-n-8xl py-n-3xl"
        data-testid="input-new-password"
        :type="showNewPassword ? 'text' : 'password'"
        data-lpignore="true"
      />
      <div @click="showNewPassword=!showNewPassword" class="absolute top-0 right-0 mt-n-5xl mr-n-3xl text-n-md font-medium cursor-pointer">
        {{$t(showNewPassword ? 'password.hide' : 'password.show')}}
      </div>
    </div>

    <div class="mt-n-4xl font-semibold">{{$t('password.enter_new_password_again')}}</div>

    <div class="relative">
      <input
        @focus="$refs.validatePassword.selectField('confirmPassword')"
        v-model="confirmPassword"
        class="v2 mt-n-2xl pl-n-4xl pr-n-8xl py-n-3xl"
        :type="showConfirmPassword ? 'text' : 'password'"
        data-testid="input-confirm-password"
        data-lpignore="true"
      />
      <div @click="showConfirmPassword=!showConfirmPassword" class="absolute top-0 right-0 mt-n-5xl mr-n-3xl text-n-md font-medium cursor-pointer">
        {{$t(showConfirmPassword ? 'password.hide' : 'password.show')}}
      </div>
    </div>

    <ValidatePassword ref="validatePassword"
      @setIsPasswordValid="setIsPasswordValid"
      :confirmPassword="confirmPassword"
      :password="newPassword"
      class="mt-n-3xl"
    />

    <HCaptcha
      ref="hCaptchaRef"
      @onChallengeExpired="onClose"
      @onClosed="onClose"
      @onError="onError"
      @onExpired="onClose"
      @onVerify="onVerify"
    />

    <div class="flex items-center justify-end mt-n-3xl">
      <ButtonV2
        :inactive="isLoading || !isFormValid"
        testId="btn-save"
        :label="$t('password.save')"
        submit
      />
    </div>
  </form>
</template>

<script>
import GoBack from '@/assets/icons/chevron_left.svg';
import ButtonV2 from '@/stories/misc/ButtonV2';
import HCaptcha from '@/components/misc/HCaptcha';
import ValidatePassword from '@/components/misc/ValidatePassword';
import PASSWORD_CHANGE from '@/graphql/mutations/PasswordChange.gql';
import { isApiError } from '@/utils/error-handler';

export default {
  name: 'CurrentPassword',

  components: {
    GoBack,
    ButtonV2,
    HCaptcha,
    ValidatePassword,
  },

  data() {
    return {
      captcha: '',
      confirmPassword: '',
      currentPassword: '',
      isLoading: false,
      isConfirmPasswordValid: false,
      isPasswordValid: false,
      newPassword: '',
      showConfirmPassword: false,
      showCurrentPassword: false,
      showNewPassword: false,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.currentPasswordRef.focus();
    });
  },

  computed: {
    isFormValid() {
      return this.currentPassword && this.newPassword && this.confirmPassword && this.isPasswordValid && this.isConfirmPasswordValid;
    },
  },

  methods: {
    goBack() {
      this.$router.go('/security');
    },

    onClose() {
      this.isLoading = false;
    },

    onError(err) {
      this.isLoading = false;
      this.showError(err);
    },

    onSubmit() {
      this.$refs.hCaptchaRef.execute();
    },

    async onVerify(captcha) {
      this.captcha = captcha;
      await this.updatePassword();
    },

    setIsPasswordValid({ isPasswordValid, isConfirmPasswordValid }) {
      this.isPasswordValid = isPasswordValid;
      this.isConfirmPasswordValid = isConfirmPasswordValid;
    },

    async updatePassword() {
      const { captcha, confirmPassword, currentPassword, newPassword, } = this.$data;

      if (newPassword !== confirmPassword) {
        return this.showError(new Error('confirm_pw_not_equals_new_pw'));
      }

      this.isLoading = true;

      try {
        await this.apolloApiCall({
          mutation: PASSWORD_CHANGE,
          variables: {
            current_password: currentPassword,
            new_password: newPassword,
            captcha,
          }
        });
        this.showSuccess(this.$t('success.password_changed'));
        this.$router.push('/security');
      } catch (err) {
        if (isApiError(err)) {
          if (err['error_code'] === 'invalid_captcha') {
            return this.onSubmit();
          }
        }

        this.isLoading = false;
        await this.showError(err);
      }
    },
  },
};
</script>

<style scoped>
</style>
