<template>
  <div class="relative mt-2-5">
    <Security class="icon-background"/>
  </div>
</template>

<script>
import Security from '@/assets/icons/security.svg';

export default {
  name: 'SecurityBackground',
  components: {
    Security,
  },
};
</script>

<style scoped>
.icon-background {
  @apply absolute opacity-10;
  width: 750px;
  height: 750px;
}
</style>
